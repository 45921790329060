import React from "react";
import { Paragraph, TextContainer } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection, Header } from "../docs";

export const Meta: DocPageMeta = {
	category: "Designing",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Illustrations FAQ"
			subTitle="Answers to frequently asked questions about illustrations."
			hideNav
		/>
		<ContentSection>
			<TextContainer article>
				<Header.H2>Work in Progress</Header.H2>
				<Paragraph>
					This page is still being developed. More information about illustrations can be found in
					the README of the{" "}
					<a href="https://github.com/Siteimprove/FancyVisuals">FancyVisuals repository</a>.
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);
